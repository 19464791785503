<template>
  <li class="nk-menu-item" :class="{active: (currentRoute === item.route)}">
    <router-link :to="{name: item.route}" class="nk-menu-link">
      <span class="nk-menu-icon" v-if="item.icon">
      <nio-icon :icon="item.icon"></nio-icon>
    </span>
      <span class="nk-menu-text text-capitalize">{{ $t(item.title) }}</span>
      <nk-menu-badge v-if="store.state.menu_badges_data[item.route]">{{store.state.menu_badges_data[item.route]}}</nk-menu-badge>
    </router-link>
  </li>
</template>

<script>
import {useStore} from "vuex";
import NkMenuBadge from "@core/components/badge/NkMenuBadge";

export default {
  components: {NkMenuBadge},
  //mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentRoute: String,
  },
  setup(){
    const store = useStore()
    return{
      store,
    }
  },
}
</script>

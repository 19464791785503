<template>
  <span class="nk-menu-badge">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "NkMenuBadge",
}
</script>

<template>
  <li class="dropdown notification-dropdown">
    <a href="javascript:;" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
      <div class="position-relative d-inline-flex" v-if="unreadCount > 0">
        <span id="notification_badge">{{ unreadCount > 99 ? '99+' : unreadCount }}</span>
        <nio-icon icon="ni-bell"></nio-icon>
      </div>
      <nio-icon icon="ni-bell" v-else></nio-icon>
    </a>
    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
      <div class="dropdown-head">
        <span class="sub-title nk-dropdown-title">Notifications</span>
        <a href="javascript:;" v-on:click="markAsRead('all')" v-if="unreadCount > 0">{{$t('Mark All as Read')}}</a>
      </div>
      <div class="dropdown-body">
        <div class="nk-notification">
          <notification-items></notification-items>
        </div><!-- .nk-notification -->
      </div><!-- .nk-dropdown-body -->
      <div class="dropdown-foot center">
        <router-link :to="{name: 'Notifications'}">View All</router-link>
      </div>
    </div>
  </li>
</template>

<script>
import NotificationItems from "@core/layouts/app-nav-bar/components/NotificationItems";
import { mapGetters} from "vuex"
import useNotifications from "@/views/notifications/useNotifications";
export default {
  name: "NotificationDropdown",
  components: {NotificationItems},
  computed: {
    ...mapGetters({
      unreadCount: 'notifications/unreadCount',
    }),
  },
  setup(){
    const{markAsRead} = useNotifications()
    return{
      markAsRead,
    }
  },
}
</script>

<template>
  <li class="dropdown user-dropdown">
    <a href="#" class="dropdown-toggle mr-n1" data-toggle="dropdown">
      <div class="user-toggle">
        <div class="user-avatar sm">
          <img v-if="userData.dp" alt="DP" :src="userData.dp"/>
          <nio-icon v-else icon="ni-user-alt"></nio-icon>
        </div>
        <div class="user-info d-none d-xl-block">
          <!--<div class="user-status user-status-unverified">Unverified</div>-->
          <div class="user-name dropdown-indicator">{{ userRole === 'is-company' ? userData.company_name: userData.first_name+' '+userData.last_name }}</div>
        </div>
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
      <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
        <div class="user-card">
          <div class="user-avatar">
            <img v-if="userData.dp" alt="DP" :src="userData.dp"/>
            <span v-else>{{ userData.initials }}</span>
          </div>
          <div class="user-info">
            <span class="lead-text">{{ userRole === 'is-company' ? userData.company_name : userData.first_name+' '+userData.last_name }}</span>
            <span class="sub-text">{{ userData.email }}</span>
          </div>
        </div>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li><router-link :to="{ name: navBarUserDropdownLinks.profile_settings.route }">
            <nio-icon icon="ni-user-alt"></nio-icon>
            <span>{{$t('View Profile')}}</span>
          </router-link>
          </li>
          <li>
            <router-link :to="{ name: navBarUserDropdownLinks.account_setting.route }">
              <nio-icon icon="ni-setting-alt"></nio-icon>
              <span>{{$t('Account Setting')}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: navBarUserDropdownLinks.login_activity.route, params: navBarUserDropdownLinks.login_activity.params}">
              <nio-icon icon="ni-activity-alt"></nio-icon>
              <span>{{$t('Login Activity')}}</span>
            </router-link>
          </li>
          <li>
            <a class="dark-switch clickable" href="javascript:;" :class="{active: darkMode}" v-on:click="toggleDarkMode">
              <nio-icon icon="ni-moon"></nio-icon>
              <span>Dark Mode</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li>
            <a href="javascript:;" v-on:click="logout">
              <nio-icon icon="ni-signout"></nio-icon>
              <span>{{$t('Sign out')}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>

import {computed, ref} from "vue";
import useLogout from "@core/comp-functions/Logout"
import storeHelper from '@/store/helper'
import {useStore} from "vuex";
import useCommonFunc from '@core/comp-functions/common';

export default {
  name: "UserDropdown",
  setup(){

    const {logout} = useLogout()
    const store = useStore()
    const{userRole} = storeHelper()
    const{navBarUserDropdownLinks} = useCommonFunc()

    const userData = computed(()=>{
      switch (userRole.value){
        case 'is-advisor':
          return store.state.advisor_data
        case 'is-admin':
          return store.state.admin_data
        case 'is-company':
        case 'is-ap-agent':
        default:
          return store.state.user_data
      }
    })

    let darkMode = ref(store.state.appConfig.dark_mode)



    const toggleDarkMode = () => {
      store.state.appConfig.dark_mode = !store.state.appConfig.dark_mode
      darkMode.value = store.state.appConfig.dark_mode
      localStorage.setItem('dark_mode', (darkMode.value ? '1' : '0'))
    }



    return {
      userData,
      navBarUserDropdownLinks,
      logout,
      darkMode,
      toggleDarkMode,
      userRole,
    }
  }
}
</script>

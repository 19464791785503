<template>
  <li class="nk-menu-heading">
    <h6 class="overline-title text-primary-alt">{{ $t(item.header) }}</h6>
  </li>
</template>

<script>
export default {
  name: "VerticalNavMenuHeader",
  props:{
    item: {
      type: Object,
      required: true,
    },
  }
}
</script>


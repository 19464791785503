<template>
  <div class="nk-sidebar nk-sidebar-fixed is-light" :class="{'is-compact': sidebarCompact, 'nk-sidebar-active': sidebarActive}">
    <div class="nk-sidebar-element nk-sidebar-head">
      <div class="nk-sidebar-brand">
        <router-link :to="{name: 'Home'}" class="logo-link nk-sidebar-logo">
          <img class="logo-light logo-img" :src="appLogoLight" :srcset="require('@/assets/images/logos/logo-light2x.png')+' 2x'" alt="logo">
          <img class="logo-dark logo-img" :src="appLogo" :srcset="require('@/assets/images/logos/logo2x.png')+' 2x'" alt="logo-dark">
          <img class="logo-small logo-img logo-img-small" :src="appLogoSmall" :srcset="require('@/assets/images/logos/logo-small2x.png')+' 2x'" alt="logo-small">
        </router-link>
      </div>
      <div class="nk-menu-trigger mr-n2">
        <a href="javascript:;" class="nk-nav-toggle nk-quick-nav-icon d-xl-none"
           v-on:click="toggleSidebarMobile">
          <nio-icon icon="ni-arrow-left"></nio-icon>
        </a>
        <a href="javascript:;" class="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" v-on:click="toggleSidebar"><em class="icon ni ni-menu"></em></a>
      </div>
    </div><!-- .nk-sidebar-element -->
    <div class="nk-sidebar-element nk-sidebar-body">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu" data-simplebar>
          <vertical-nav-menu-items :items="menuItems"></vertical-nav-menu-items>
        </div>
      </div>
    </div>
  </div>
  <div class="nk-sidebar-overlay" v-if="sidebarOverlay" v-on:click="toggleSidebarMobile"></div>

</template>

<script>

import {$themeConfig} from "../../../../themeConfig";

import VerticalNavMenuItems from './VerticalNavMenuItems'
import {useStore} from "vuex"
import {computed, ref, watch} from "vue";
import useAdvisorMenu from "@/navigation/vertical/basic";
import admin from "@/navigation/vertical/admin";
import apAgent from '@/navigation/vertical/ap-agent'
import companyMenu from '@/navigation/vertical/company'

const { appLogo, appLogoSmall, appLogoLight} = $themeConfig.app;

export default {
  components: {
    VerticalNavMenuItems
  },
  setup(){

    const store = useStore()
    const{advisorMenu} = useAdvisorMenu()
    let sidebarCompact = ref(false)
    sidebarCompact.value = store.state.verticalMenu.toggleCompact
    const toggleSidebar = () => {
      sidebarCompact.value = !sidebarCompact.value
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COMPACT', sidebarCompact.value)
    }

    const menuItems = computed(()=>{
      let m = [];
      switch (store.state.auth.user_role){
        case 'is-advisor':
          m = advisorMenu
          break;
        case 'is-admin':
          m = admin
          break;
        case 'is-ap-agent':
          m = apAgent
          break;
        case 'is-company':
          m = companyMenu
          break;
        default:
          m = []
          break;
      }
      return m;
    })

    const breaks = {
      mb: 420,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1540,
      any: 1 / 0
    }
    let sidebarActive = ref(false)
    let sidebarOverlay = ref(false)
    watch(() => store.state.verticalMenu.toggleActive, (newVal) => {
      sidebarActive.value = newVal
      let ww = window.outerWidth
      sidebarOverlay.value = newVal && ww < breaks.xl
    });

    const toggleSidebarMobile = () => {
      sidebarActive.value = !sidebarActive.value
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE', sidebarActive.value)
      document.getElementsByTagName('body')[0].classList.toggle('nav-shown')
    }

    return {
      appLogo,
      appLogoSmall,
      appLogoLight,
      menuItems,
      toggleSidebar,
      sidebarCompact,
      sidebarActive,
      toggleSidebarMobile,
      sidebarOverlay,
    }
  }
}
</script>
<!--<style lang="scss" scoped>
@import "~@core/scss/core/layouts/_menu-main.scss";
</style>-->


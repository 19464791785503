<template>
  <li class="dropdown language-dropdown d-none d-sm-block mr-n1">
    <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown" aria-expanded="false">
      <div class="user-avatar xs border border-light">
        <img :src="require('@/assets/images/flags/'+activeLang+'-sq.png')" alt="">
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right dropdown-menu-s1" style="">
      <ul class="language-list">
        <li>
          <a href="javascript:;" class="language-item" v-on:click="changeLang('en')">
            <img :src="require('@/assets/images/flags/english.png')" alt="EN" class="language-flag"><span class="language-name">{{ $t('English') }}</span>
            <nio-icon icon="ni-check" v-if="activeLang === 'en'" class="flex-fill text-right" style="font-size: 1rem;"></nio-icon>
          </a>
        </li>
        <li>
          <a href="javascript:;" class="language-item" v-on:click="changeLang('nl')">
            <img :src="require('@/assets/images/flags/netherland.png')" alt="NL" class="language-flag"><span class="language-name">Dutch</span>
            <nio-icon icon="ni-check" v-if="activeLang === 'nl'" class="flex-fill text-right" style="font-size: 1rem;"></nio-icon>
          </a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {loadingController} from "@ionic/vue";
import i18n from "@/libs/i18n";

export default {
  setup(){
    const store = useStore()
    const changeLang = async (lang) => {

      const l = await loadingController.create({message: i18n.global.t('Processing...')})
      await l.present()
      store.dispatch('appConfig/change_language', lang).then(()=> i18n.global.locale = lang)
          .catch().then(()=>l.dismiss())
    }
    return{
      changeLang,
      activeLang: computed(()=> store.state.appConfig.lang)
    }
  }
}
</script>
